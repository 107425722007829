import React from "react";
import { navigate } from "gatsby";
import { Button, Col, Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const ButtonNavigation = ({
  linkNext,
  linkNextTo,
  linkPrevious,
  linkPreviousTo,
}) => {
  return (
    <Row justify="center" align="middle" className="background-lines">
      <Col
        style={{
          padding: "48px",
        }}
      >
        {linkPreviousTo && (
          <Button
            primary
            type="dashed"
            onClick={(event) => {
              event.preventDefault();
              navigate(linkPreviousTo);
            }}
            style={{ margin: "0 6px" }}
          >
            <LeftOutlined />
            {linkPrevious}
          </Button>
        )}
        {linkNextTo && (
          <Button
            primary
            onClick={(event) => {
              event.preventDefault();
              navigate(linkNextTo);
            }}
            style={{ margin: "0 6px" }}
          >
            {linkNext}
            <RightOutlined />
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default ButtonNavigation;
