import React from "react";
import PropTypes from "prop-types";
import { MDXProvider } from "@mdx-js/react";
import { StaticQuery, graphql, navigate } from "gatsby";
import { Divider, PageHeader, Row, Col, Typography, List, Space } from "antd";
import Seo from "../components/seo";
import PageHeaderMenu from "../components/ui/PageHeaderMenu";
import ContactForm from "../components/forms/ContactForm.js";
import Footer from "../components/sections/Footer.js";
import { ShareAltOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import ButtonsNavigation from "../components/ui/ButtonsNavigation.js";
import "../styles/layout.scss";
import "prismjs/themes/prism-okaidia.css";

const { Title, Paragraph, Text } = Typography;

const LayoutChart = ({ children, pageContext }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            company
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet>
          <script src="/js/iubenda.js" type="text/javascript" />
          <script
            type="text/javascript"
            src="https://cdn.iubenda.com/cs/tcf/stub-v2.js"
          ></script>
          <script
            type="text/javascript"
            src="https://cdn.iubenda.com/cs/ccpa/stub.js"
          ></script>
          <script
            type="text/javascript"
            src="https://cdn.iubenda.com/cs/iubenda_cs.js"
            charset="UTF-8"
            async
          ></script>
        </Helmet>
        <Seo
          title="inFlow Documentation"
          description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
        />
        <PageHeader
          style={{
            width: "100%",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "15000",
            backgroundColor: "#fff",
            borderBottom: "1px solid #ced4da",
            // padding: "0 12px",
            height: "67px",
          }}
          onBack={() => navigate("/")}
          backIcon={<ShareAltOutlined style={{ transform: "rotate(90deg)" }} />}
          subTitle={
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
              onKeyDown={() => navigate("/")}
              role="link"
              tabIndex={0}
            >
              {data.site.siteMetadata.title}
            </span>
          }
          extra={[<PageHeaderMenu />]}
        />
        <Row
          justify="center"
          align="middle"
          className="heading"
          style={{ backgroundColor: "#ffffff" }}
        >
          <Col xs={24} sm={20} md={20} lg={12} xl={10}>
            <Title>Documentation</Title>
            <Title level={2} style={{ color: "white" }}>
              {pageContext.frontmatter.title}
            </Title>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            padding: "72px 24px",
          }}
        >
          <Col xs={24} sm={20} md={20} lg={12} xl={10}>
            <MDXProvider
              components={{
                h1: (props) => (
                  <Title id={props.children} level={1} {...props} />
                ),
                h2: (props) => (
                  <Title id={props.children} level={2} {...props} />
                ),
                h3: (props) => (
                  <Title id={props.children} level={3} {...props} />
                ),
                h4: (props) => (
                  <Title id={props.children} level={4} {...props} />
                ),
                ul: (props) => (
                  <List
                    id={props.children}
                    bordered
                    style={{ marginBottom: "1em" }}
                    {...props}
                  />
                ),
                li: (props) => <List.Item id={props.children} {...props} />,
                p: (props) => <Paragraph {...props} />,
                hr: () => <Divider dashed style={{ margin: "48px 0" }} />,
                ButtonsNavigation,
              }}
            >
              {children}
              <Space style={{ marginTop: "48px" }}>
                <Text type="secondary" style={{ fontSize: "10px" }}>
                  Last edit:
                </Text>
                <Text style={{ fontSize: "10px" }}>
                  {pageContext.frontmatter.edit}
                </Text>
              </Space>
            </MDXProvider>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="section-text"
          style={{ backgroundColor: "#f1f3f5" }}
        >
          <Col xs={24} sm={20} md={20} lg={16} xl={12}>
            <ContactForm message="We're here if you need us.." />
          </Col>
        </Row>
        <Footer />
      </>
    )}
  />
);

LayoutChart.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutChart;
