import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/layouts/LayoutDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Welcome to the `}<a parentName="p" {...{
        "href": "/"
      }}>{`inFlow`}</a>{` documentation!`}</p>
    <p>{`In our documentation we aim to give the information you need to make the best use of our service. We have information on different topics on working with inFlow charts.`}</p>
    <p>{`Click a topic below to start.`}</p>
    <h2>{`Topics`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/embedding-charts/introduction"
        }}>{`Embedding charts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/performance/introduction"
        }}>{`Performance`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/using-urls/introduction"
        }}>{`Using URL's`}</a></li>
    </ul>
    <hr></hr>
    <h3>{`Need help?`}</h3>
    <p>{`If you have any questions related to our services contact us directly below.`}</p>
    <hr></hr>
    <h3>{`Improving documentation`}</h3>
    <p>{`We're continously trying to improve our documentation. If you have any comments or remarks please get in touch!`}</p>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      